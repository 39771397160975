export const environment = {
  production: true,
  api: "localhost:8080",
  backend: 'https://dev.geoking.site', // test
  transifex_api: '0b78e7a23e644bedb4b510326eead9a7',
  clientUrl: 'https://dev.geoking.site',
  adminUrl: 'https://dev.geoking.site/admin',
  gtm: 'GTM-KNKMXCG8',
  zoho_project_id: '702765000038326005',
  zoho_org_id: '773804226',
  sentry: {
    sentryAuthToken: '06bf0743b3eb42708990524d6998d08729d1aaea9c244d88b0e8368d8c423b38',
    sentryDSN: '',
    project: '',
    org: ''
  },
  firebase: {
    projectId: 'geoking-dev',
    appId: '1:971162810991:web:6c0ff5a9c768f62be2ab9a',
    storageBucket: 'geoking-dev.appspot.com',
    authDomain: 'geoking-dev.firebaseapp.com',
    messagingSenderId: '971162810991',
    apiKey: 'AIzaSyCs0LEWCThfMEp0sGBBcdMEkKOloGAw4ec'
  },
};
